/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { func, arrayOf, string } from 'prop-types';
import { GoTriangleLeft, GoTriangleRight } from 'react-icons/go';

function SeatCategoryPicker({ category, setCategory, setSelectedSeats }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCategory(category[currentIndex]);
  }, [currentIndex]);

  const getNextCategory = () => {
    if (currentIndex === (category.length - 1)) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
    setSelectedSeats([]);
  };

  const getPrevCategory = () => {
    if (currentIndex === 0) {
      setCurrentIndex(category.length - 1);
    } else {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
    setSelectedSeats([]);
  };

  return (
    <div className="d-flex justify-content-between mb-2">
      <button onClick={getPrevCategory} className="border border-none"><GoTriangleLeft className="p-0" /></button>
      <h3>{category && category[currentIndex]}</h3>
      <button onClick={getNextCategory} className="border border-none"><GoTriangleRight /></button>
    </div>
  );
}

SeatCategoryPicker.propTypes = {
  category: arrayOf(string).isRequired,
  setCategory: func.isRequired,
  setSelectedSeats: func.isRequired,
};

export default SeatCategoryPicker;
