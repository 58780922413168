import React from 'react';
import { func, string } from 'prop-types';

function DeleteButton({ onDelete, id, styles }) {
  return <button className={`btn btn-danger ${styles}`} type="button" onClick={() => onDelete(id)}>Delete</button>;
}

DeleteButton.propTypes = {
  onDelete: func.isRequired,
  id: string.isRequired,
  styles: string.isRequired,
};

export default DeleteButton;
