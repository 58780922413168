/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState, useContext } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { func } from 'prop-types';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import useInput from '../hooks/useInput';
import AuthedContext from '../contexts/AuthedContext';
import { getBooking, putAccessToken } from '../utils/api';
import SeatClassFilter from '../components/SeatClassFilter';
import ShipRouteFilter from '../components/ShipRouteFilter';
import TicketButton from '../components/TicketButton';
import DetailButton from '../components/DetailButton';
import ShipFilter from '../components/ShipFilter';
import SubsidyToggler from '../components/SubsidyToggler';
import StatusBox from '../components/StatusBox';
import LoadingSpinner from '../components/LoadingSpinner';

function BookingData({ setInitiate }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { authedUser } = useContext(AuthedContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [seatClass, setSeatClass] = useInput('ALL');
  // const [shipId, setShipId] = useInput(authedUser.shipId);
  const [shipRouteId, setShipRouteId] = useInput('');
  const dateParam = searchParams.get('date') || moment().format('YYYY-MM-D');
  const [bookingData, setBookingData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [shipId, setShipId] = useInput('');
  const [subsidyStatus, setSubsidyStatus] = useState(false);
  const [selectedDate, setSelectedDate] = useState(() => (
    moment(dateParam, 'YYYY-MM-D', true).isValid() ? dateParam : moment().format('YYYY-MM-D')
  ));

  const setFormattedDate = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-D');
    setSelectedDate(formattedDate);
    setSearchParams({ date: formattedDate });
  };

  const toggleSubsidyStatus = () => {
    setSubsidyStatus(!subsidyStatus);
  };

  useEffect(() => {
    if (shipRouteId && shipId) {
      setLoading(true);
      getBooking(selectedDate, shipRouteId, seatClass, shipId, subsidyStatus)
        .then(({ statusCode, errorMessage, data }) => {
          if (data) {
            setBookingData(data);
            setLoading(false);
          } else if (statusCode === 401) {
            Swal.fire(
              'Failed',
              'Session anda telah habis, silahkan login kembali',
              'error',
            );
            putAccessToken('');
            setInitiate();
          } else {
            Swal.fire('Error', errorMessage, 'error');
            setLoading(false);
          }
        });
    }
  }, [shipRouteId, shipId, selectedDate, seatClass, authedUser, subsidyStatus]);

  const onTicketHandler = (id) => {
    navigate('/ticket', { state: { id } });
  };

  const filteredData = bookingData.filter((row) => (
    row.customer_name.toLowerCase().includes(searchKeyword.toLowerCase())
    || row.id.toLowerCase().includes(searchKeyword.toLowerCase())
  ));

  return (
    <section>
      <div className="row mb-2">
        <div className="col-sm-6 col-md-2 mb-2">
          <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={new Date(selectedDate)} onChange={(date) => setFormattedDate(date)} />
        </div>
        <div className="col-sm-6 col-md-3 mb-2">
          <ShipRouteFilter selectedId={shipRouteId} setSelectedId={setShipRouteId} />
        </div>
        <div className="col-sm-6 col-md-2 mb-2">
          <SeatClassFilter seatClass={seatClass} setSeatClass={setSeatClass} />
        </div>
        <div className="col-sm-6 col-md-3 mb-2">
          <ShipFilter selectedId={shipId} setSelectedId={setShipId} routeId={shipRouteId} />
        </div>

        <div className="col-md-2">
          <Link to="/manifest" state={{ bookingData, dateParam, seatClass }} className="btn btn-success w-100">Print Manifest</Link>
        </div>

        <div className="col-12 mb-2">
          <SubsidyToggler subsidy={subsidyStatus} toggleSubsidy={toggleSubsidyStatus} />
        </div>

        <div className="col-md-6 align-self-end">
          <p className="m-0 ">
            <span className="fw-bold">Tiket Terjual : </span>{bookingData.length} | <span className="fw-bold">Total Penjualan : </span>{`Rp. ${bookingData.reduce((acc, item) => acc + parseInt(item.price, 10), 0).toLocaleString('id-ID')}`}
          </p>
        </div>

        <div className="col-md-4 d-flex align-items-center ms-auto">
          <label className="text-nowrap me-1" htmlFor="searchInput">Cari Data: </label>
          <input id="searchInput" onChange={(e) => setSearchKeyword(e.target.value)} type="text" className="form-control form-control-sm" placeholder="Cari nama atau invoice..." />
        </div>

        <div className="col-12 mt-1">
          <div className="table-responsive-lg">
            <table className="table custom-table table-striped table-hover table-bordered">
              <thead>
                <tr>
                  <th className="text-start py-3" scope="col">Invoice</th>
                  <th className="py-3 text-nowrap" scope="col">id Kapal</th>
                  <th className="py-3" scope="col">Kursi</th>
                  {
                    bookingData.length > 0 && (bookingData[0].embark_id === 'MWR' || bookingData[0].embark_id === 'WSR')
                    && <th className="py-3" scope="col">Rute</th>
                  }
                  <th className="py-3 text-nowrap" scope="col">Nama Penumpang</th>
                  <th className="py-3" scope="col">Gender</th>
                  <th className="py-3" scope="col">Kategori</th>
                  <th className="py-3 text-nowrap" scope="col">Kontak Penumpang</th>
                  <th className="py-3" scope="col">Harga</th>
                  <th className="py-3" scope="col">Operator</th>
                  {
                    (authedUser.role !== 'operator' && authedUser.role.slice(0, -1) !== 'comprador')
                    && <th className="py-3" scope="col">Status</th>
                  }
                  {
                    (authedUser.role === 'admin' || authedUser.role === 'master')
                    && <th className="py-3 text-center" scope="col" colSpan="2">Aksi</th>
                  }
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {
                  loading ? (
                    <tr>
                      <td colSpan="11" className="text-center">
                        <LoadingSpinner />
                      </td>
                    </tr>
                  )
                    : filteredData.length === 0
                      ? (
                        <tr>
                          <td colSpan="11"><p className="text-center text-warning m-0">Data tidak ditemukan</p></td>
                        </tr>
                      )
                      : filteredData.map((row) => (
                        <tr key={row.id}>
                          <td>{row.id}</td>
                          <td>{row.ship_id}</td>
                          <td>{row.seat_class + row.seat_number}</td>
                          {
                            (row.embark_id === 'MWR' || row.embark_id === 'WSR')
                            && <td>{`${row.embarkation} - ${row.destination}`}</td>
                          }
                          <td>{row.customer_name}</td>
                          <td>{row.customer_gender === 'male' ? 'Pria' : 'Wanita'}</td>
                          <td>{row.ticket_category === 'adult' ? 'Dewasa' : 'Anak'}</td>
                          <td>{row.customer_contact}</td>
                          <td>{parseInt(row.price, 10).toLocaleString('id-ID')}</td>
                          <td>{row.operator_name}</td>
                          {
                            (authedUser.role !== 'operator' && authedUser.role.slice(0, -1) !== 'comprador')
                            && <td><StatusBox status={row.status} /></td>
                          }
                          {
                            (authedUser.role === 'admin' || authedUser.role === 'master')
                            && (
                              <td colSpan="2" className="d-flex gap-2">
                                <Link to={`/bookingdata/${row.id}`} state={{ shipRouteId }}>
                                  <DetailButton />
                                </Link>
                                <TicketButton onTicket={onTicketHandler} id={row.id} />
                              </td>
                            )
                          }
                        </tr>
                      ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}

BookingData.propTypes = {
  setInitiate: func.isRequired,
};

export default BookingData;
