/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useContext } from 'react';
import {
  useParams,
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  putAccessToken,
  getBookingDetail,
  deleteBooking,
  putBooking,
} from '../utils/api';
import AuthedContext from '../contexts/AuthedContext';
import useInput from '../hooks/useInput';
import Forbidden from './Forbidden';
import DeleteButton from '../components/DeleteButton';
import UpdateButton from '../components/UpdateButton';

function BookingDetail() {
  const navigate = useNavigate();
  const { state: { shipRouteId } } = useLocation();
  const { id } = useParams();
  const { authedUser } = useContext(AuthedContext);
  const [name, setName] = useInput('');
  const [contact, setContact] = useInput('');
  const [date, setDate] = useInput('');
  // const [seat, setSeat] = useInput('');
  // const [ticketCategory, setTicketCategory] = useInput('');
  // const [ticketClass, setTicketClass] = useInput('');
  const [responseStatus, setResponseStatus] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line camelcase
    getBookingDetail(id).then(({ statusCode, errorMessage, data }) => {
      if (data) {
        setName(data.customer_name);
        setContact(data.customer_contact);
        setDate(data.date);
        // setSeat(data.seat_number);
        // setTicketCategory(data.ticket_category);
        // setTicketClass(data.seat_class);
        setResponseStatus(true);
      } else if (statusCode === 401) {
        Swal.fire(
          'Failed',
          'Session anda telah habis, silahkan login kembali',
          'error',
        );
        putAccessToken('');
      } else {
        Swal.fire('Not Found', errorMessage, 'error');
      }
    });
  }, []);

  const onDeleteHandler = (deleteId) => {
    Swal.fire({
      title: 'Data akan dihapus.',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Confirm!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBooking(deleteId, authedUser.id).then(({ statusCode, message }) => {
          if (statusCode === 200) {
            Swal.fire('Success', message, 'success');
            navigate(`../bookingdata?date=${date}`);
          } else if (statusCode === 401) {
            Swal.fire(
              'Failed',
              'Session anda telah habis, silahkan login kembali',
              'error',
            );
            putAccessToken('');
          } else {
            Swal.fire('Failed', message, 'error');
          }
        });
      }
    });
  };

  const onUpdateHandler = (event) => {
    event.preventDefault();

    Swal.fire({
      title: 'Data akan diupdate.',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Confirm!',
    }).then((result) => {
      if (result.isConfirmed) {
        putBooking(id, {
          customer_name: name,
          customer_contact: contact,
        }).then(({ statusCode, message }) => {
          if (statusCode === 200) {
            Swal.fire('Success', message, 'success');
            navigate(`../bookingdata?date=${date}`);
          } else if (statusCode === 401) {
            Swal.fire(
              'Failed',
              'Session anda telah habis, silahkan login kembali',
              'error',
            );
            putAccessToken('');
          } else {
            Swal.fire('Failed', message, 'error');
          }
        });
      }
    });
  };

  if (!shipRouteId) {
    return <Forbidden />;
  }

  return (
    <div className="row justify-content-center">
      <div className="col-lg-10">
        <h3 className="text-center">{`Detail Id : ${id}`}</h3>

        <form onSubmit={onUpdateHandler}>
          <div className="form-group mb-2">
            <label className="form-label">Nama</label>
            <input className="form-control" onChange={setName} value={name} />
          </div>

          <div className="form-group mb-2">
            <label className="form-label">Kontak</label>
            <input className="form-control" onChange={setContact} value={contact} />
          </div>

          {
            responseStatus && (
              <div className="mt-4">
                <UpdateButton styles="d-inline-block me-2" onUpdate={onUpdateHandler} id={id} type="submit" />
                <DeleteButton styles="d-inline-block" onDelete={onDeleteHandler} id={id} />
              </div>
            )
          }

          <br />
          <Link to={`/bookingdata?date=${date}`}>Kembali ke tabel</Link>
        </form>
      </div>
    </div>
  );
}

export default BookingDetail;
