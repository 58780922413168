import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import Swal from 'sweetalert2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { putAccessToken, getBookingSummary, getBookingStatistic } from '../utils/api';
// import ShipRouteFilter from '../components/ShipRouteFilter';
import ShipFilter from '../components/ShipFilter';
import useInput from '../hooks/useInput';
import ShipRouteFilter from '../components/ShipRouteFilter';
import LoadingSpinner from '../components/LoadingSpinner';

function getMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('id-ID', {
    month: 'long',
  });
}

function DataStatistic({ setInitiate }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );

  const [loading, setLoading] = useState(false);
  const [shipRouteId, setShipRouteId] = useInput('');
  const [shipId, setShipId] = useInput('');
  const [year, setYear] = useInput(new Date().getFullYear());
  const [month, setMonth] = useInput(new Date().getMonth() + 1);
  const [bookingSummary, setBookingSummary] = useState(null);
  const [dailyStatistic, setDailyStatistic] = useState(null);
  const [monthlyStatistic, setMonthlyStatistic] = useState(null);

  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Statistik pendapatan harian bulan ${getMonthName(month)}`,
      },
    },
  };

  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Statistik pendapatan bulanan tahun ${year}`,
      },
    },
  };

  let lineChartData;
  if (dailyStatistic) {
    lineChartData = {
      labels: dailyStatistic.dailyLabel,
      datasets: [
        {
          label: shipId,
          data: dailyStatistic.dailyPrice,
          borderColor: '#0275d8',
          backgroundColor: '#0275d8',
        },
      ],
    };
  }

  let barChartData;
  if (monthlyStatistic) {
    barChartData = {
      labels: monthlyStatistic.monthlyLabel,
      datasets: [
        {
          label: shipId,
          data: monthlyStatistic.monthlyPrice,
          backgroundColor: '#0275d8',
        },
      ],
    };
  }

  useEffect(() => {
    if (shipId) {
      getBookingSummary(shipId).then(({ statusCode, errorMessage, data }) => {
        if (data) {
          setBookingSummary(data.bookingSummary);
        } else if (statusCode === 401) {
          Swal.fire(
            'Failed',
            'Session anda telah habis, silahkan login kembali',
            'error',
          );
          putAccessToken('');
          setInitiate(true);
        } else {
          alert(errorMessage);
        }
      });
    }
  }, [shipId]);

  useEffect(() => {
    if (shipId) {
      setLoading(true);
      getBookingStatistic(shipId, year, month).then(({ statusCode, errorMessage, data }) => {
        if (data) {
          setDailyStatistic(data.dailyStatistic);
          setMonthlyStatistic(data.monthlyStatistic);
          setLoading(false);
        } else if (statusCode === 401) {
          Swal.fire(
            'Failed',
            'Session anda telah habis, silahkan login kembali',
            'error',
          );
          putAccessToken('');
          setInitiate(true);
        } else {
          alert(errorMessage);
          setDailyStatistic([]);
          setMonthlyStatistic([]);
          setLoading(false);
        }
      });
    }
  }, [shipId, year, month]);

  return (
    <section>
      <div className="row">
        <div className="col-md-3 mb-1 mb-md-0" hidden>
          <ShipRouteFilter selectedId={shipRouteId} setSelectedId={setShipRouteId} />
        </div>
        <div className="col-md-3 mb-1 mb-md-0">
          <ShipFilter selectedId={shipId} setSelectedId={setShipId} routeId={shipRouteId} />
        </div>
        <div className="col-md-3 mb-1 mb-md-0">
          <select className="form-select" value={month} onChange={setMonth}>
            <option value="1">Januari</option>
            <option value="2">Februari</option>
            <option value="3">Maret</option>
            <option value="4">April</option>
            <option value="5">Mei</option>
            <option value="6">Juni</option>
            <option value="7">Juli</option>
            <option value="8">Agustus</option>
            <option value="9">September</option>
            <option value="10">Oktober</option>
            <option value="11">November</option>
            <option value="12">Desember</option>
          </select>
        </div>
        <div className="col-md-3 mb-1 mb-md-0">
          <select className="form-select" value={year} onChange={setYear}>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
          </select>
        </div>
      </div>

      {
        bookingSummary && (
          <div className="row mt-4">
            <p>{`Ship Id : ${shipId}`}</p>
            <div className="col-md-6 col-xl-3">
              <div className="card border-secondary mb-4">
                <div className="card-body">
                  <p className="card-text">Pendapatan Hari Ini :</p>
                  <h3 className="card-title text-success">{`Rp. ${bookingSummary.price_total_today ? parseInt(bookingSummary.price_total_today, 10).toLocaleString('id-ID') : 0}`}</h3>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-between">
                  <a className="small stretched-link" href="./booking-data">Lihat Detail</a>
                  <div className="small"><i className="fas fa-angle-right" /></div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="card border-secondary mb-4">
                <div className="card-body">
                  <p className="card-text">Pendapatan Bulan Ini :</p>
                  <h3 className="card-title text-success">{`Rp. ${bookingSummary.price_total_monthly ? parseInt(bookingSummary.price_total_monthly, 10).toLocaleString('id-ID') : 0}`}</h3>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-between">
                  <a className="small stretched-link" href="./booking-data">Lihat Detail</a>
                  <div className="small"><i className="fas fa-angle-right" /></div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="card border-secondary mb-4">
                <div className="card-body">
                  <p className="card-text">Tiket Terjual Hari Ini :</p>
                  <h3 className="card-title text-success">{bookingSummary.ticket_sold_today}</h3>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-between">
                  <a className="small stretched-link" href="./booking-data">Lihat Detail</a>
                  <div className="small"><i className="fas fa-angle-right" /></div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="card border-secondary mb-4">
                <div className="card-body">
                  <p className="card-text">Tiket Terjual Bulan Ini :</p>
                  <h3 className="card-title text-success">{bookingSummary.ticket_sold_monthly}</h3>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-between">
                  <a className="small stretched-link" href="booking-data">Lihat Detail</a>
                  <div className="small"><i className="fas fa-angle-right" /></div>
                </div>
              </div>
            </div>
          </div>
        )
      }

      <div className="row">
        <div className="col-lg-6">
          {
            loading ? <LoadingSpinner />
              : dailyStatistic && <Line data={lineChartData} options={lineChartOptions} />
          }
        </div>
        <div className="col-lg-6">
          {
            loading ? <LoadingSpinner />
              : monthlyStatistic && <Bar data={barChartData} options={barChartOptions} />
          }
        </div>
      </div>
    </section>
  );
}

DataStatistic.propTypes = {
  setInitiate: func.isRequired,
};

export default DataStatistic;
