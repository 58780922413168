/* eslint-disable no-unused-vars */
import React from 'react';
import {
  arrayOf,
  array,
  func,
  object,
  string,
} from 'prop-types';
import SeatCol from './SeatCol';

function SeatRow({
  onSeatSelected,
  justify,
  parts,
  sd,
}) {
  return (
    <div className={`row justify-content-${justify}`}>
      {
        parts.map((part) => (
          <div className="col-auto mx-1">
            <div className="row">
              {
                part.map(({ status, seat }) => (
                  <SeatCol key={`col-${seat}`} onSelected={onSeatSelected} status={status} seat={seat} sd={sd} />
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  );
}

SeatRow.propTypes = {
  onSeatSelected: func.isRequired,
  justify: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  parts: arrayOf(array).isRequired,
  sd: string.isRequired,
};

export default SeatRow;
