import React, { useEffect, useState, useContext } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import Swal from 'sweetalert2';
import moment from 'moment';
import AuthedContext from '../contexts/AuthedContext';
import { getTicketData, putTicketStatus } from '../utils/api';

function ScanTicket() {
  const [html5QrcodeScanner, setHtml5QrcodeScanner] = useState(null);
  const { authedUser } = useContext(AuthedContext);
  const [bookData, setBookData] = useState(null);
  const [ticketStatus, setTicketStatus] = useState(null);
  const onScanSuccess = (decodedText) => {
    getTicketData(decodedText).then(({ data, errorMessage }) => {
      if (data) {
        setBookData(data);
        setTicketStatus(data.status);
        html5QrcodeScanner.clear();
      } else {
        Swal.fire('Error', errorMessage, 'error');
      }
    });
  };

  const startScanning = () => {
    setBookData(null);
    // eslint-disable-next-line no-nested-ternary
    const qrBoxSize = window.innerWidth >= 576
      ? { width: 250, height: 250 } : { width: 150, height: 150 };
    setHtml5QrcodeScanner(
      new Html5QrcodeScanner(
        'scan-area',
        { fps: 1, qrbox: qrBoxSize },
        false,
      ),
    );
  };

  const confirmTicket = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Confirm!',
    }).then((result) => {
      if (result.isConfirmed) {
        putTicketStatus(bookData.id, authedUser.id).then(({ statusCode, message }) => {
          if (statusCode === 200) {
            Swal.fire('Scan Success!', message, 'success');
            setBookData(null);
            setHtml5QrcodeScanner(
              new Html5QrcodeScanner(
                'scan-area',
                { fps: 1, qrbox: { width: 250, height: 250 } },
                false,
              ),
            );
          } else {
            Swal.fire('Scan Failed!', message, 'error');
          }
        });
      }
    });
  };

  useEffect(() => {
    if (!html5QrcodeScanner) {
      startScanning();
    }
  }, []);

  useEffect(() => {
    if (html5QrcodeScanner) {
      html5QrcodeScanner.render(onScanSuccess);
    }

    return () => {
      if (html5QrcodeScanner) {
        html5QrcodeScanner.clear();
      }
    };
  }, [html5QrcodeScanner]);

  return (
    <section>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div id="scan-area" style={{ width: '100%' }} />
          <div className="row">
            <div className="col-md-12">
              {
                bookData
                  && (
                    <>
                      <div className="row">
                        <div className="col text-center">
                          {
                            ticketStatus === 'un-check' && <h5 className="px-3 py-1 text-bg-warning rounded-1 d-inline-block">{ticketStatus.toUpperCase()}</h5>
                          }
                          {
                            ticketStatus === 'check-in' && <h5 className="px-3 py-1 text-bg-success rounded-1 d-inline-block">{ticketStatus.toUpperCase()}</h5>
                          }
                          {
                            ticketStatus === 'aboard' && <h5 className="px-3 py-1 text-bg-primary rounded-1 d-inline-block">{ticketStatus.toUpperCase()}</h5>
                          }
                        </div>
                      </div>

                      <table className="table table-borderless text-start">
                        <tr>
                          <td>Nomor Tiket</td>
                          <td>:</td>
                          <th>{bookData.id}</th>
                        </tr>
                        <tr>
                          <td>Kapal</td>
                          <td>:</td>
                          <th>{bookData.ship_name}</th>
                        </tr>
                        <tr>
                          <td>Nomor Kursi</td>
                          <td>:</td>
                          <td>{`${bookData.seat_class + bookData.seat_number}`}</td>
                        </tr>
                        <tr>
                          <td>Rute</td>
                          <td>:</td>
                          <td>{`${bookData.embarkation} - ${bookData.destination}`}</td>
                        </tr>
                        <tr>
                          <td>Tanggal</td>
                          <td>:</td>
                          <td>{moment(bookData.date).format('D-MM-YYYY')}</td>
                        </tr>
                        <tr>
                          <td>Jam</td>
                          <td>:</td>
                          <td>{bookData.depart_hour}</td>
                        </tr>
                        <tr>
                          <td>Nama</td>
                          <td>:</td>
                          <td>{bookData.customer_name}</td>
                        </tr>
                        <tr>
                          <td>Gender</td>
                          <td>:</td>
                          <td>{bookData.customer_gender === 'male' ? 'Pria' : 'Wanita'}</td>
                        </tr>
                        <tr>
                          <td>Kategori</td>
                          <td>:</td>
                          <td>{bookData.ticket_category === 'adult' ? 'Dewasa' : 'Anak'}</td>
                        </tr>
                        <tr>
                          <td>Operator</td>
                          <td>:</td>
                          <td>{authedUser.name}</td>
                        </tr>
                        <tr>
                          <td>Harga Tiket</td>
                          <td>:</td>
                          <td>{`Rp. ${parseInt(bookData.price, 10).toLocaleString('id-ID')}`}</td>
                        </tr>
                      </table>

                      <div className="row">
                        <div className="col">
                          <button onClick={startScanning} type="button" className="btn btn-warning">Scan Again</button>
                          <button onClick={confirmTicket} type="button" className="btn btn-success ms-2">Confirm</button>
                        </div>
                      </div>
                    </>
                  )
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ScanTicket;
