import React from 'react';
import { string } from 'prop-types';

function StatusBox({ status }) {
  let boxColor;

  switch (status) {
    case 'terbayar':
      boxColor = 'text-bg-secondary';
      break;
    case 'terscan':
      boxColor = 'text-bg-success';
      break;
    case 'terlewat':
      boxColor = 'text-bg-warning';
      break;
    case 'berangkat':
      boxColor = 'text-bg-primary';
      break;
    default:
      boxColor = '';
  }

  if (status) {
    return (
      <span className={`p-2 rounded-1 text-capitalize ${boxColor}`}>
        {status}
      </span>
    );
  }
}

StatusBox.propTypes = {
  status: string.isRequired,
};

export default StatusBox;
