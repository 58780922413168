import React from 'react';
import { bool, func } from 'prop-types';

function SubsidyToggler({ subsidy, toggleSubsidy }) {
  return (
    <div className="form-check">
      <label className="form-check-label" htmlFor="subsidyToggler">
        Subsidi
        <input
          value={subsidy}
          onChange={toggleSubsidy}
          className="form-check-input"
          type="checkbox"
          id="subsidyToggler"
        />
      </label>
    </div>
  );
}

SubsidyToggler.propTypes = {
  subsidy: bool.isRequired,
  toggleSubsidy: func.isRequired,
};

export default SubsidyToggler;
