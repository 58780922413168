import React from 'react';
import { func, string } from 'prop-types';
import { ImTicket } from 'react-icons/im';

function TicketButton({ onTicket, id }) {
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  return <button className="btn btn-sm btn-warning" type="button" onClick={() => onTicket(id)}><ImTicket /></button>;
}

TicketButton.propTypes = {
  onTicket: func.isRequired,
  id: string.isRequired,
};

export default TicketButton;
