/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { func, string } from 'prop-types';
import { login, putAccessToken } from '../utils/api';
import useInput from '../hooks/useInput';

function LoginPage({ setInitiate, message, setMessage }) {
  const [username, setUsername] = useInput('');
  const [password, setPassword] = useInput('');

  const onLogin = (event) => {
    event.preventDefault();
    setInitiate(true);
    login({ username, password }).then(({ errorMessage, data }) => {
      if (data) {
        putAccessToken(data.accessToken);
      } else {
        setMessage(errorMessage);
      }
      setInitiate(true);
    });
  };

  return (
    <div className="login-body">
      <div className="login">
        <h1 className="text-center fw-bold mb-3">Welcome</h1>
        {
          message && (
            <div className="alert alert-danger text-center" role="alert">
              {message}
            </div>
          )
        }
        <form onSubmit={onLogin}>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="username">Username</label>
            <input
              className="form-control"
              name="username"
              type="text"
              onChange={setUsername}
              value={username}
            />
          </div>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="password">Password</label>
            <input
              className="form-control"
              type="password"
              onChange={setPassword}
              value={password}
            />
          </div>
          <button className="btn btn-success w-100" type="submit">SIGN IN</button>
        </form>
      </div>
    </div>
  );
}

LoginPage.propTypes = {
  setInitiate: func.isRequired,
  setMessage: func.isRequired,
  message: string.isRequired,
};

export default LoginPage;
