import React, { useEffect, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { string, func } from 'prop-types';
import { getShipRouteList } from '../utils/api';
import AuthedContext from '../contexts/AuthedContext';

function ShipRouteFilter({ selectedId, setSelectedId }) {
  const [searchParams] = useSearchParams();
  const { authedUser } = useContext(AuthedContext);
  const [routeList, setRouteList] = useState(null);

  useEffect(() => {
    getShipRouteList(authedUser.id, authedUser.agencyId).then(({ errorMessage, data }) => {
      if (data) {
        setRouteList(data);
      } else {
        alert(errorMessage);
      }
    });
  }, []);

  useEffect(() => {
    if (!selectedId) {
      if (routeList) {
        setSelectedId(searchParams.get('shiproute') || routeList[0].route_id);
      }
    }
  }, [routeList]);

  return (
    <select id="shipRouteSelector" className="form-select" value={selectedId} onChange={setSelectedId}>
      {
        routeList && routeList.map((route) => (
          <option value={route.route_id} key={route.route_id}>{`${route.embarkation} - ${route.destination}`}</option>
        ))
      }
    </select>
  );
}

ShipRouteFilter.propTypes = {
  selectedId: string.isRequired,
  setSelectedId: func.isRequired,
};

export default ShipRouteFilter;
