import React from 'react';

function PrintButton() {
  const onPrint = () => {
    window.print();
  };

  return (
    <button type="button" onClick={onPrint}>Print</button>
  );
}

export default PrintButton;
