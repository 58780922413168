import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { string, func } from 'prop-types';
import AuthedContext from '../contexts/AuthedContext';
import { getShipList } from '../utils/api';

function ShipFilter({ selectedId, setSelectedId, routeId }) {
  const [searchParams] = useSearchParams();
  const { authedUser } = useContext(AuthedContext);
  const [shipList, setShipList] = useState(null);

  useEffect(() => {
    if (routeId && authedUser.id) {
      getShipList(routeId, authedUser.id).then(({ errorMessage, data }) => {
        if (data) {
          setShipList(data);
        } else {
          alert(errorMessage);
        }
      });
    }
  }, [routeId]);

  useEffect(() => {
    if (shipList) {
      setSelectedId(searchParams.get('shipid') || shipList[0].id);
    }
  }, [shipList]);

  return (
    <select className="form-select" id="shipFilter" value={selectedId} onChange={setSelectedId}>
      {
        shipList && shipList.map((ship) => (
          <option value={ship.id} key={ship.id}>{ship.name}</option>
        ))
      }
    </select>
  );
}

ShipFilter.propTypes = {
  selectedId: string.isRequired,
  setSelectedId: func.isRequired,
  routeId: string.isRequired,
};

export default ShipFilter;
