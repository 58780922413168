/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { func } from 'prop-types';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useInput from '../hooks/useInput';
import SeatPicker from '../components/Seats/SeatPicker';
import SeatCategoryPicker from '../components/Seats/SeatCategoryPicker';
import AuthedContext from '../contexts/AuthedContext';
import {
  getPriceList,
  postBooking,
  putAccessToken,
} from '../utils/api';
import SubsidyToggler from '../components/SubsidyToggler';
import ShipRouteFilter from '../components/ShipRouteFilter';
import DepartHourFilter from '../components/DepartHourFilter';
import PassengerInput from '../components/PassengerInput';
import ShipFilter from '../components/ShipFilter';

function BookingTicket({ setInitiate }) {
  const navigate = useNavigate();
  const { authedUser } = useContext(AuthedContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSeats, setSelectedSeats] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [seatCategory, setSeatCategory] = useState(['VIP', 'A', 'B', 'C', 'D', 'E', 'F']);
  const [selectedSeatCategory, setSelectedSeatCategory] = useState(seatCategory[0]);
  const [users, setUsers] = useState([]);
  const [prices, setPrices] = useState(null);
  const [subsidyStatus, setSubsidyStatus] = useState(false);
  // const [agencyId, setAgencyId] = useInput(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shipRouteId, setShipRouteId] = useInput('');
  const [shipId, setShipId] = useInput('');
  const [departHour, setDepartHour] = useInput('');

  const toggleSubsidyStatus = () => {
    setSubsidyStatus(!subsidyStatus);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (authedUser.role === 'master' || authedUser.role === 'operator') {
      if (users.length <= 0) {
        alert('Silahkan pilih kursi terlebih dulu');
        return;
      }

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Confirm!',
      }).then((result) => {
        if (result.isConfirmed) {
          const requestData = users.map((user) => ({
            ship_route_id: shipRouteId,
            ship_id: shipId,
            depart_hour: departHour,
            seat_class: selectedSeatCategory.toUpperCase(),
            date: moment(selectedDate).format('YYYY-MM-D'),
            seat_number: user.seat,
            customer_gender: user.gender,
            customer_age: user.age,
            ticket_category: user.ticketCategory,
            customer_name: user.name,
            customer_contact: user.contact,
            price: user.price,
            subsidy: subsidyStatus,
            operator_id: authedUser.id,
          }));

          postBooking(requestData).then(({ statusCode, message, data }) => {
            if (data) {
              navigate('/ticket', { state: { dataInput: data } });
            } else if (statusCode === 401) {
              Swal.fire(
                'Failed',
                'Session anda telah habis, silahkan login kembali',
                'error',
              );
              putAccessToken('');
              setInitiate(true);
            } else {
              if (statusCode === 409) {
                Swal.fire(
                  'Insert Failed!',
                  'Data already exist!',
                  'error',
                );
                window.location.reload();
                return;
              }
              Swal.fire(
                'Insert Failed!',
                message,
                'error',
              );
            }
          });
        }
      });
    } else {
      alert('Anda tidak memiliki akses pada fitur tersebut');
    }
  };

  useEffect(() => {
    if (shipRouteId) {
      getPriceList(shipRouteId).then(({ errorMessage, data }) => {
        if (!data) {
          alert(errorMessage);
        } else if (selectedSeatCategory === 'VIP') {
          setPrices({
            vipPrices: true,
            adultVip: data.adult_vip,
            childVip: data.child_vip,
            subsidyAdultVip: data.subsidy_adult_vip,
            subsidyChildVip: data.subsidy_child_vip,
          });
        } else {
          setPrices({
            adultExecutive: data.adult_executive,
            childExecutive: data.child_executive,
            subsidyAdultExecutive: data.subsidy_adult_executive,
            subsidyChildExecutive: data.subsidy_child_executive,
          });
        }
      });
    }
  }, [shipRouteId, selectedSeatCategory, subsidyStatus]);

  useEffect(() => {
    setTotalPrice(users.reduce((userPrices, user) => userPrices + parseInt(user.price, 10), 0).toLocaleString('id-ID'));
  }, [users]);

  return (
    <section>
      <div className="row">
        <div className="col-lg-5 mb-5 mb-lg-0">
          <div className="row ">
            <div className="col text-center">
              <DatePicker
                selected={selectedDate}
                minDate={new Date()}
                onChange={(date) => setSelectedDate(date)}
                inline
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <SeatCategoryPicker
                category={seatCategory}
                setSelectedSeats={setSelectedSeats}
                setCategory={setSelectedSeatCategory}
              />
            </div>
            <div className="col-12">
              <SeatPicker
                category={selectedSeatCategory}
                selected={selectedSeats}
                setSelected={setSelectedSeats}
                date={selectedDate}
                subsidyStatus={subsidyStatus}
                shipRouteId={shipRouteId}
                shipId={shipId}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="row align-items-center">
            <div className="col-sm-2 mb-2">
              <SubsidyToggler subsidy={subsidyStatus} toggleSubsidy={toggleSubsidyStatus} />
            </div>
            <div className="col-sm-4 col-md-2 mb-2">
              {
                shipRouteId && (
                  <DepartHourFilter
                    selectedId={departHour}
                    setSelectedId={setDepartHour}
                    routeId={shipRouteId}
                  />
                )
              }
            </div>
            <div className="col-sm-6 col-md-4 mb-2">
              <ShipRouteFilter selectedId={shipRouteId} setSelectedId={setShipRouteId} />
            </div>
            <div className="col-sm-12 col-md-4 mb-2">
              <ShipFilter selectedId={shipId} setSelectedId={setShipId} routeId={shipRouteId} />
            </div>
          </div>
          <form onSubmit={submitHandler}>
            <div className="alert alert-success text-center" role="alert">
              { `Total Harga : Rp. ${totalPrice}` }
            </div>

            <label className="my-3">{`Tanggal Keberangkatan : ${selectedDate}`}</label>
            <br />

            {
              selectedSeats.sort()
                .map((seat) => (
                  <PassengerInput
                    seat={seat}
                    setUsers={setUsers}
                    subsidyStatus={subsidyStatus}
                    prices={prices}
                    key={seat}
                  />
                ))
            }

            <button type="submit" className="btn btn-primary mt-4 rounded-1">Submit</button>
          </form>
        </div>
      </div>
    </section>
  );
}

BookingTicket.propTypes = {
  setInitiate: func.isRequired,
};

export default BookingTicket;
