/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  string,
  arrayOf,
  func,
  bool,
  instanceOf,
} from 'prop-types';
import SeatRow from './SeatRow';
import { getSeatList } from '../../utils/api';

function SeatSchema({
  selected,
  setSelected,
  date,
  category,
  subsidyStatus,
  shipRouteId,
  shipId,
}) {
  const [seats, setSeats] = useState([]);
  const [seatSchema, setSeatSchema] = useState(null);
  const [displaySchema, setDisplaySchema] = useState(null);

  useEffect(() => {
    if (shipRouteId && shipId) {
      getSeatList(`seat-${category.toLowerCase()}`, moment(date).format('YYYY/MM/D'), shipRouteId, shipId).then(({ errorMessage, data }) => {
        // if error seatLoading true
        if (data) {
          setSelected([]);
          setSeats(data.seatListStatus);
          setSeatSchema(data.seatSchema);
        } else {
          alert(errorMessage);
        }
      });
    }

    return () => {
      setDisplaySchema(null);
    };
  }, [date, category, subsidyStatus, shipId, shipRouteId]);

  useEffect(() => {
    if (seatSchema) {
      let seatIndex = 0;

      if (seats) {
        setDisplaySchema(() => (
          seatSchema.schema.map((row) => ({
            ...row,
            parts: seatSchema.order === 'normal'
              ? row.parts.map((part) => Array.from({ length: part }, (_, index) => index + 1).map(() => seats[seatIndex++]))
              : row.parts.map((part) => Array.from({ length: part }, (_, index) => index + 1).map(() => seats[seatIndex++]).reverse()).reverse(),
          }))
        ));
      }
    }
  }, [seatSchema]);

  const onSeatSelected = (event) => {
    const col = event.target.getAttribute('data-set');

    if (selected.includes(col)) {
      setSelected((prevValue) => prevValue.filter((value) => value !== col));
      event.target.classList.remove('bg-primary');
      return;
    }

    if (selected.length >= 6) {
      // eslint-disable-next-line no-alert
      alert('Batas memilih kursi adalah 6');
      return;
    }
    setSelected((prevValue) => [...prevValue, col]);
    event.target.classList.add('bg-primary');
  };

  let index = 1;
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-auto">
          {
            displaySchema && displaySchema.map((row) => (
              <SeatRow
              // eslint-disable-next-line react/jsx-props-no-spreading
                {...row}
                // eslint-disable-next-line react/no-array-index-key
                key={`row-${index++}`}
                order={seatSchema.order}
                sd={seatSchema.sd}
                onSeatSelected={onSeatSelected}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
}

SeatSchema.propTypes = {
  category: string.isRequired,
  date: instanceOf(Date).isRequired,
  selected: arrayOf(string).isRequired,
  setSelected: func.isRequired,
  subsidyStatus: bool.isRequired,
  shipRouteId: string.isRequired,
  shipId: string.isRequired,
};

export default SeatSchema;
