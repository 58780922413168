// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';

const useInput = (value = '') => {
  const [input, setInput] = useState(value);

  const setInputValue = (event) => {
    if (event.target) {
      setInput(event.target.value);
    } else {
      setInput(event);
    }
  };

  return [input, setInputValue];
};

export default useInput;
