import React from 'react';
import { func, string } from 'prop-types';

function UpdateButton({
  onUpdate,
  id,
  styles,
  type = 'button',
}) {
  // eslint-disable-next-line react/button-has-type
  return <button type={type} className={`btn btn-warning ${styles}`} onClick={() => onUpdate(id)}>Update</button>;
}

UpdateButton.propTypes = {
  onUpdate: func.isRequired,
  // eslint-disable-next-line react/require-default-props
  type: string,
  id: string.isRequired,
  styles: string.isRequired,
};

UpdateButton.defaultProps = {
  type: 'button',
};

export default UpdateButton;
