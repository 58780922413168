import React, { useContext } from 'react';
import AuthedContext from '../contexts/AuthedContext';

function Dashboard() {
  const { authedUser } = useContext(AuthedContext);
  return (
    <>
      <h2>{`Halo ${authedUser.name}`}</h2>
      <p>{`Anda mempunyai akses pada rute dengan Id ${authedUser.agencyId} dengan hak akses ${authedUser.role}`}</p>
    </>
  );
}

export default Dashboard;
