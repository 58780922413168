/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import {
  string,
  func,
  object,
  bool,
} from 'prop-types';
import useInput from '../hooks/useInput';

function PassengerInput({
  seat,
  setUsers,
  prices,
  subsidyStatus,
}) {
  const [gender, setGender] = useInput('male');
  const [age, setAge] = useInput('');
  const [name, setName] = useInput('');
  const [contact, setContact] = useInput('');

  useEffect(() => {
    let price;

    if (!subsidyStatus) {
      if (prices.vipPrices) {
        if (age > 12) {
          price = prices.adultVip;
        } else {
          price = prices.childVip;
        }
      } else if (age > 12) {
        price = prices.adultExecutive;
      } else {
        price = prices.childExecutive;
      }
    } else if (prices.vipPrices) {
      if (age > 12) {
        price = prices.subsidyAdultVip;
      } else {
        price = prices.subsidyChildVip;
      }
    } else if (age > 12) {
      price = prices.subsidyAdultExecutive;
    } else {
      price = prices.subsidyChildExecutive;
    }

    setUsers((prevUsers) => [...prevUsers, {
      seat,
      gender,
      age,
      ticketCategory: age > 12 ? 'adult' : 'child',
      price,
      name,
      contact,
    }]);

    return () => {
      setUsers((prevUsers) => prevUsers.filter((user) => user.seat !== seat));
    };
  }, [gender, age, name, contact]);

  return (
    <div className="row mb-3 align-items-center">
      <div className="col-1">
        <label>{seat}</label>
      </div>
      <div className="col-11">
        <div className="row">
          <div className="col-md-6">
            <input className="form-control" placeholder="Nama penumpang" onChange={setName} value={name} />
          </div>
          <div className="col-md-6">
            <input className="form-control" placeholder="Kontak penumpang" onChange={setContact} value={contact} />
          </div>
          <div className="col-md-6">
            <select className="form-select" name="gender" value={gender} onChange={setGender}>
              <option value="male">Pria</option>
              <option value="female">Wanita</option>
            </select>
          </div>
          <div className="col-md-6">
            <input className="form-control" type="number" min="1" name="ticketCategory" placeholder="Umur penumpang" value={age} onChange={setAge} />
          </div>
        </div>
      </div>
    </div>
  );
}

PassengerInput.propTypes = {
  seat: string.isRequired,
  setUsers: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  prices: object.isRequired,
  subsidyStatus: bool.isRequired,
};

export default PassengerInput;
