import React from 'react';
import {
  string,
  func,
  arrayOf,
  bool,
  instanceOf,
} from 'prop-types';
// import SeatSchemaVIP from './SeatSchemaVIP';
// import SeatSchemaA from './SeatSchemaA';
// import SeatSchemaB from './SeatSchemaB';
// import SeatSchemaC from './SeatSchemaC';
// import SeatSchemaD from './SeatSchemaD';
import SeatSchema from './SeatSchema';

function SeatPicker({
  category,
  selected,
  setSelected,
  date,
  subsidyStatus,
  shipRouteId,
  shipId,
}) {
  return (
    <SeatSchema
      selected={selected}
      setSelected={setSelected}
      date={date}
      category={category}
      subsidyStatus={subsidyStatus}
      shipRouteId={shipRouteId}
      shipId={shipId}
    />
  );
}

SeatPicker.propTypes = {
  category: string.isRequired,
  selected: arrayOf(string).isRequired,
  setSelected: func.isRequired,
  date: instanceOf(Date).isRequired,
  subsidyStatus: bool.isRequired,
  shipRouteId: string.isRequired,
  shipId: string.isRequired,
};

export default SeatPicker;
