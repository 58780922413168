import React from 'react';
import { func, string } from 'prop-types';

function SeatClassFilter({ seatClass, setSeatClass }) {
  return (
    <select className="form-select" value={seatClass} onChange={setSeatClass}>
      <option value="" disabled>{`Jenis Kursi : ${seatClass}`}</option>
      <option value="ALL">ALL</option>
      <option value="VIP">VIP</option>
      <option value="A">A</option>
      <option value="B">B</option>
      <option value="C">C</option>
      <option value="D">D</option>
      <option value="E">E</option>
      <option value="F">F</option>
    </select>
  );
}

SeatClassFilter.propTypes = {
  seatClass: string.isRequired,
  setSeatClass: func.isRequired,
};

export default SeatClassFilter;
