import React from 'react';
import { number, func, bool } from 'prop-types';

function SeatCol({ onSelected, seat, status }) {
  const onBookedSeatSelected = () => {
    alert('Kursi yang anda pilih sudah dipesan');
  };

  if (status) {
    return <div data-set={seat} className="text-bg-danger text-center m-1 seat" tabIndex="-1" role="button" onClick={onBookedSeatSelected} onKeyPress={onSelected} key={seat}>{seat}</div>;
  }

  return <div data-set={seat} className="text-bg-success text-center m-1 seat" tabIndex="0" role="button" onClick={onSelected} onKeyPress={onSelected} key={seat}>{seat}</div>;
}

SeatCol.propTypes = {
  onSelected: func.isRequired,
  status: bool.isRequired,
  seat: number.isRequired,
};

export default SeatCol;
