/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import { func } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import useInput from '../hooks/useInput';
import AuthedContext from '../contexts/AuthedContext';
import { putNewPassword, putAccessToken } from '../utils/api';

function UserPage({ setInitiate }) {
  const navigate = useNavigate();
  const { authedUser } = useContext(AuthedContext);
  const [oldPassword, setOldPassword] = useInput('');
  const [newPassword, setNewPassword] = useInput('');
  const [newPassword2, setNewPassword2] = useInput('');

  const onChangePassword = (event) => {
    event.preventDefault();

    if (newPassword !== newPassword2) {
      Swal.fire(
        'Warning',
        'Konfirmasi password baru salah',
        'warning',
      );
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Confirm!',
    }).then((result) => {
      if (result.isConfirmed) {
        putNewPassword(authedUser.id, {
          oldPassword,
          newPassword,
        }).then(({ statusCode, message }) => {
          if (statusCode === 200) {
            Swal.fire('Success', message, 'success');
            navigate('../');
          } else if (statusCode === 401) {
            Swal.fire(
              'Failed',
              'Session anda telah habis, silahkan login kembali',
              'error',
            );
            putAccessToken('');
            setInitiate(true);
          } else {
            Swal.fire('Failed', message, 'error');
          }
        });
      }
    });
  };

  return (
    <section>
      <h2 className="mb-2 text-center">Ubah Password</h2>
      <div className="row justify-content-between">
        <div className="col-12 col-md-3 text-center px-5 px-md-0 mb-3 mb-md-0">
          <h3>{authedUser.name}</h3>
          <img src="images/profile.png" className="img-thumbnail w-75 rounded-circle border border-3 border-dark" alt="profile" />
        </div>

        <div className="col-12 col-md-9">
          <form onSubmit={onChangePassword}>
            <div className="mb-3">
              <label htmlFor="oldPassword" className="form-label">Password Lama</label>
              <input type="password" className="form-control" id="oldPassword" onChange={setOldPassword} value={oldPassword} />
            </div>
            <div className="mb-3">
              <label htmlFor="newPassword" className="form-label">Password Baru</label>
              <input type="password" className="form-control" id="newPassword" onChange={setNewPassword} value={newPassword} />
            </div>
            <div className="mb-3">
              <label htmlFor="renewPassword" className="form-label">Konfirmasi Password Baru</label>
              <input type="password" className="form-control" id="renewPassword" onChange={setNewPassword2} value={newPassword2} />
            </div>
            <button className="btn btn-primary" type="submit">Submit</button>
          </form>
        </div>
      </div>
    </section>
  );
}

UserPage.propTypes = {
  setInitiate: func.isRequired,
};

export default UserPage;
