/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { BsTextLeft } from 'react-icons/bs';
import { func } from 'prop-types';

function Navbar({ setShowSidebar }) {
  const onShowSidebar = () => {
    setShowSidebar((prevValue) => !prevValue);
  };

  return (
    <nav className="navbar navbar-dark bg-dark sticky-top flex-md-nowrap py-0 px-2 d-print-none">
      <BsTextLeft onClick={onShowSidebar} className="text-white fs-3" />
      <div className="navbar-nav">
        <div className="nav-item text-nowrap">
          <a className="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#logoutModal">Sign out</a>
        </div>
      </div>
    </nav>
  );
}

Navbar.propTypes = {
  setShowSidebar: func.isRequired,
};

export default Navbar;
