/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { func } from 'prop-types';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import LoadingSpinner from '../components/LoadingSpinner';
import AuthedContext from '../contexts/AuthedContext';
import useInput from '../hooks/useInput';
import { getBookingRecapData, putAccessToken } from '../utils/api';

function DataRecap({ setInitiate }) {
  const [loading, setLoading] = useState(false);
  const { authedUser } = useContext(AuthedContext);
  const [recapData, setRecapData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useInput('monthly');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-D'));
  const [selectedMonth, setSelectedMonth] = useInput(new Date().getMonth() + 1);

  useEffect(() => {
    setLoading(true);
    getBookingRecapData(authedUser.id, selectedPeriod, selectedDate, selectedMonth)
      .then(({ statusCode, errorMessage, data }) => {
        if (data) {
          setRecapData(data);
          setLoading(false);
        } else if (statusCode === 401) {
          Swal.fire(
            'Failed',
            'Session anda telah habis, silahkan login kembali',
            'error',
          );
          putAccessToken('');
          setInitiate();
        } else {
          Swal.fire('Error', errorMessage, 'error');
          setLoading(false);
        }
      });
  }, [selectedPeriod, selectedDate, selectedMonth]);

  const setFormattedDate = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-D');
    setSelectedDate(formattedDate);
  };

  const filteredData = recapData.filter((row) => (
    row.operator.toLowerCase().includes(searchKeyword.toLowerCase())
  ));

  let secondOption = '';
  if (selectedPeriod === 'daily') {
    secondOption = (
      <div className="col-sm-2 mb-2">
        <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={new Date(selectedDate)} onChange={(date) => setFormattedDate(date)} />
      </div>
    );
  } else if (selectedPeriod === 'monthly') {
    secondOption = (
      <div className="col-sm-2 mb-2">
        <select className="form-select" onChange={setSelectedMonth} value={selectedMonth}>
          <option value="1">Januari</option>
          <option value="2">Februari</option>
          <option value="3">Maret</option>
          <option value="4">April</option>
          <option value="5">Mei</option>
          <option value="6">Juni</option>
          <option value="7">Juli</option>
          <option value="8">Agustus</option>
          <option value="9">September</option>
          <option value="10">Oktober</option>
          <option value="11">November</option>
          <option value="12">Desember</option>
        </select>
      </div>
    );
  }

  return (
    <section>
      <div className="row mb-2">
        <div className="col-sm-2 mb-2">
          <select className="form-select" onChange={setSelectedPeriod} value={selectedPeriod}>
            <option value="monthly">Bulanan</option>
            <option value="daily">Harian</option>
          </select>
        </div>

        { secondOption }

        <div className="col-sm-4 d-flex align-items-center ms-auto">
          <label className="text-nowrap me-1" htmlFor="searchInput">Cari Data: </label>
          <input id="searchInput" onChange={(e) => setSearchKeyword(e.target.value)} type="text" className="form-control form-control-sm" placeholder="Cari operator..." />
        </div>

        <div className="col-12">
          <div className="table-responsive-lg">
            <table className="table custom-table table-striped table-hover table-bordered">
              <thead>
                <tr>
                  <th className="py-2" scope="col">Id Rute</th>
                  <th className="py-2" scope="col">Operator</th>
                  <th className="py-2 text-nowrap" scope="col">Jumlah Transaksi</th>
                  <th className="py-2" scope="col">Pendapatan</th>
                  <th className="py-2" scope="col">Tanggal Transaksi</th>
                  {/* <th className="py-2 text-center" scope="col" colSpan="2">Aksi</th> */}
                </tr>
              </thead>
              <tbody className="table-group-divider">
                { loading ? (
                  <tr>
                    <td colSpan="5" className="text-center">
                      <LoadingSpinner />
                    </td>
                  </tr>
                ) : filteredData.length === 0 ? (
                  <tr>
                    <td colSpan="5">
                      <p className="text-center text-warning m-0">
                        Data tidak ditemukan
                      </p>
                    </td>
                  </tr>
                ) : filteredData.map((row) => (
                  <tr key={row.id}>
                    <td>{row.rute_id}</td>
                    <td>{row.operator}</td>
                    <td>{row.jumlah_transaksi}</td>
                    <td>{`Rp. ${new Intl.NumberFormat('id-ID').format(row.total_penjualan) ?? 0}`}</td>
                    <td>{moment(row.date).format('DD-MM-YYYY')}</td>
                    {/* <td className="text-center d-flex gap-2">Aksi</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}

DataRecap.propTypes = {
  setInitiate: func.isRequired,
};

export default DataRecap;
