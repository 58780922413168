import React, { useEffect, useState, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { getUserLogged } from './utils/api';
import { AuthedContextProvider } from './contexts/AuthedContext';
import AdminPage from './layouts/AdminPage';
import Dashboard from './pages/Dashboard';
import BookingData from './pages/BookingData';
import BookingDetail from './pages/BookingDetail';
import BookingTicket from './pages/BookingTicket';
import LoginPage from './pages/LoginPage';
import Manifest from './pages/Manifest';
import Ticket from './pages/Ticket';
import ScanTicket from './pages/ScanTicket';
import UserPage from './pages/UserPage';
import DataRecap from './pages/DataRecap';
import DataStatistic from './pages/DataStatistic';
import DeletedData from './pages/DeletedData';

function App() {
  const [authedUser, setAuthedUser] = useState(null);
  const [initiate, setInitiate] = useState(true);
  const [message, setMessage] = useState(null);

  const authedUserValue = useMemo(() => ({
    authedUser,
    setAuthedUser,
  }), [authedUser]);

  useEffect(() => {
    getUserLogged().then(({ error, data }) => {
      if (!error) {
        setAuthedUser(data);
      } else {
        setAuthedUser(null);
      }
    }).finally(() => {
      setInitiate(false);
    });
  }, [initiate]);

  if (initiate) {
    return (
      <div className="overlay">
        <div className="d-flex justify-content-center">
          <div className="spinner-grow text-primary" role="status" style={{ width: '3rem', height: '3rem', zindex: 20 }}>
            <span className="sr-only" />
          </div>
        </div>
      </div>
    );
  }

  if (authedUser === null) {
    return (
      <LoginPage
        setInitiate={setInitiate}
        // setAuthedUser={setAuthedUser}
        message={message}
        setMessage={setMessage}
      />
    );
  }

  return (
    <AuthedContextProvider value={authedUserValue}>
      <Routes>
        <Route
          path="/*"
          element={(
            <AdminPage>
              <Dashboard />
            </AdminPage>
          )}
        />
        <Route
          path="/"
          element={(
            <AdminPage>
              <Dashboard />
            </AdminPage>
          )}
        />
        <Route
          path="/bookingticket"
          element={(
            <AdminPage>
              <BookingTicket setInitiate={setInitiate} />
            </AdminPage>
          )}
        />
        <Route
          path="/bookingdata"
          element={(
            <AdminPage>
              <BookingData setInitiate={setInitiate} />
            </AdminPage>
          )}
        />
        <Route
          path="/bookingdata/:id"
          element={(
            <AdminPage>
              <BookingDetail setInitiate={setInitiate} />
            </AdminPage>
          )}
        />
        <Route
          path="/scanticket"
          element={(
            <AdminPage>
              <ScanTicket setInitiate={setInitiate} />
            </AdminPage>
          )}
        />
        <Route
          path="/manifest"
          element={(<Manifest />)}
        />
        <Route
          path="/ticket"
          element={(<Ticket setInitiate={setInitiate} />)}
        />
        <Route
          path="/user-profile"
          element={(
            <AdminPage>
              <UserPage setInitiate={setInitiate} />
            </AdminPage>
          )}
        />
        {
          (authedUser.role !== 'comprador1' && authedUser.role !== 'comprador2') && (
            <Route
              path="/data-recap"
              element={(
                <AdminPage>
                  <DataRecap setInitiate={setInitiate} />
                </AdminPage>
              )}
            />
          )
        }
        {
            authedUser.role === 'master' && (
              <>
                <Route
                  path="/deleted-data"
                  element={(
                    <AdminPage>
                      <DeletedData setInitiate={setInitiate} />
                    </AdminPage>
                  )}
                />
                <Route
                  path="/data-statistic"
                  element={(
                    <AdminPage>
                      <DataStatistic setInitiate={setInitiate} />
                    </AdminPage>
                  )}
                />
              </>
            )
          }
      </Routes>
    </AuthedContextProvider>
  );
}

export default App;
