import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { string, func } from 'prop-types';
import { getDepartHours } from '../utils/api';

function DepartHourFilter({ selectedId, setSelectedId, routeId }) {
  const [searchParams] = useSearchParams();
  const [departHourList, setDepartHourList] = useState(null);

  useEffect(() => {
    if (routeId) {
      getDepartHours(routeId).then(({ errorMessage, data }) => {
        if (data) {
          setDepartHourList(data);
        } else {
          alert(errorMessage);
        }
      });
    }
  }, [routeId]);

  useEffect(() => {
    if (departHourList) {
      setSelectedId(searchParams.get('departhour') || departHourList[0]);
    }
  }, [departHourList]);

  return (
    <select className="form-select" value={selectedId} onChange={setSelectedId}>
      <option value="" disabled>{`Jam : ${selectedId}`}</option>
      {
        departHourList && departHourList.map((departHour) => (
          <option value={departHour}>{departHour}</option>
        ))
      }
    </select>
  );
}

DepartHourFilter.propTypes = {
  selectedId: string.isRequired,
  setSelectedId: func.isRequired,
  routeId: string.isRequired,
};

export default DepartHourFilter;
