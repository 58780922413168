/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { func } from 'prop-types';
import moment from 'moment';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import ShipRouteFilter from '../components/ShipRouteFilter';
import { getDeletedBookingData, putAccessToken } from '../utils/api';
import useInput from '../hooks/useInput';
import StatusBox from '../components/StatusBox';
import LoadingSpinner from '../components/LoadingSpinner';

function DeletedData({ setInitiate }) {
  const [loading, setLoading] = useState(false);
  const [deletedBooking, setDeletedBooking] = useState([]);
  const [shipRouteId, setShipRouteId] = useInput('');
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  useEffect(() => {
    if (shipRouteId && dateFrom && dateTo) {
      setLoading(true);
      getDeletedBookingData(shipRouteId, moment(dateFrom).format('YYYY-MM-D'), moment(dateTo).format('YYYY-MM-D'))
        .then(({ statusCode, errorMessage, data }) => {
          if (data) {
            setDeletedBooking(data.custDeletedBookings);
            setLoading(false);
          } else if (statusCode === 401) {
            Swal.fire(
              'Failed',
              'Session anda telah habis, silahkan login kembali',
              'error',
            );
            putAccessToken('');
            setInitiate(true);
          } else {
            alert(errorMessage);
            setLoading(false);
          }
        });
    }
  }, [shipRouteId, dateFrom, dateTo]);

  return (
    <section>
      <div className="row mb-2">
        <div className="row col-12 col-sm-9 mb-2 mb-sm-0">
          <div className="col-5">
            <DatePicker className="form-control" role="button" dateFormat="dd/MM/yyyy" selected={dateFrom} onChange={(date) => setDateFrom(date)} />
          </div>
          <div className="col-1 text-center">
            <AiOutlineArrowRight className="p-0" />
          </div>
          <div className="col-5">
            <DatePicker className="form-control " role="button" dateFormat="dd/MM/yyyy" selected={dateTo} onChange={(date) => setDateTo(date)} />
          </div>
        </div>
        <div className="col-12 col-sm-3">
          <ShipRouteFilter role="button" selectedId={shipRouteId} setSelectedId={setShipRouteId} />
        </div>
      </div>

      {/* <h1>{selectedDate}</h1> */}

      <div className="row">
        <div className="col">
          <div className="table-responsive">
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>ID_Kapal</th>
                  <th>Kursi</th>
                  <th>Nama</th>
                  <th>Harga</th>
                  <th>Operator</th>
                  <th>Dihapus_Pada</th>
                  <th>Dihapus_Oleh</th>
                  <th>Status</th>
                  <th>Discan_Pada</th>
                  <th>Discan_Oleh</th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {
                  loading ? (
                    <tr>
                      <td colSpan="11" className="text-center">
                        <LoadingSpinner />
                      </td>
                    </tr>
                  )
                    : deletedBooking.length === 0
                      ? (
                        <tr>
                          <td colSpan="11"><p className="text-center text-warning m-0">Tabel kosong</p></td>
                        </tr>
                      )
                      : deletedBooking.map((row) => (
                        <tr>
                          <td>{row.id}</td>
                          <td>{row.ship_id}</td>
                          <td>{row.seat_number}</td>
                          <td>{row.customer_name}</td>
                          <td>{parseInt(row.price, 10).toLocaleString('id-ID')}</td>
                          <td>{row.operator_name}</td>
                          <td>{row.deleted_at}</td>
                          <td>{row.deleted_operator_name}</td>
                          <td>
                            {
                              // eslint-disable-next-line no-nested-ternary
                              row.scanned_at
                                ? row.scanned_at > row.deleted_at
                                  ? <span className="text-bg-danger p-1 rounded-1">Terindikasi</span>
                                  : <StatusBox status={row.status} />
                                : <StatusBox status={row.status} />
                            }
                          </td>
                          <td>{row.scanned_at}</td>
                          <td>{row.scanned_operator_name}</td>
                        </tr>
                      ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}

DeletedData.propTypes = {
  setInitiate: func.isRequired,
};

export default DeletedData;
